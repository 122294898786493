import React, { useState, useEffect } from 'react';
import HeroCard from './HeroCard';
import useRequest from '../../../useRequest';
import Slider from 'react-slick';
// const fetchData = async () => {
//     try {
      
//       const response = await fetch('https://api.grownetfinance.com/api/slider-slide-list/');
//       if (response.status === 200) {
       
//       }
      
//       return response.data;
//     } catch (error) {
//       console.error("Error due to",error)
//       throw new Error('Failed to fetch data');
//     }
// };
const HeroData = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

   const { data, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    
    sendRequest({ path: '/slider-slide-list', key: 'Slider Api',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
    
  }, [sendRequest]);
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    if (data) {
      setAllData(data.data);
    }
  }, [data]);

  if (queryLoading) {
    return <p className='m-0'>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }

  return (
    <>
      <Slider {...settings}>
        {allData.length > 0 &&
          allData.map((item) => (
            <HeroCard
              key={item.id}
              title={item.heading}
              subHeading={item.sub_heading}
              linkAppointment={"https://apply.grownetfinance.com/"}
              appointmentUrl={item.appointment_button_title}
              linkApply={item.apply_button_url}
              applyUrl={item.apply_button_title}
              userImageUrl={item.user_image}
              userCount={item.user_count}
              userTitle={item.user_title}
              sliderImageUrl={item.slider_image}
            />
          ))}
      </Slider>
    </>
  );
};

export default HeroData;
