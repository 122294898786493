import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../Images/Image'
import Heading from '../Headings/Heading'

import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';

const HeroCard = ({title, subHeading, linkAppointment, appointmentUrl, linkApply, applyUrl, userImageUrl, userCount, userTitle, sliderImageUrl}) => {
    
    
    
    
    return (
        <>
           <div className='slider--item' >
                <Container>
                    <Row className='slider--inner' >
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h1" className="h1" title={title} />
                            <Heading tagName="h5" className="h5" title={subHeading} />
                            <div className='slider-button'>
                                <Link to={linkAppointment} className="btnOutline button-1 btn-custom">{appointmentUrl}</Link>
                                {/* <Link to={linkApply} className="btnfilled button-2 btn-custom">{applyUrl}</Link> */}
                            </div>
                            
                        </Col>

                        <Col className='col-12 col-lg-6'>
                            <Image
                                
                                src={sliderImageUrl}
                                alt={title}
                                className="mainBanner"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
                    
            
            
        </>
    )
}

export default HeroCard
