import React from 'react'
import '../Footer/Footer.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import FooterTop from '../../templates/FooterTop/FooterTop';
import NewsLetter from '../../templates/NewsLetter/NewsLetter';
import Heading from '../../molecules/Headings/Heading';
import Image from '../../molecules/Images/Image';
import whatsAppImage from '../../../assets/img/whatsappIcon.svg'
import FooterAddress from '../../templates/FooterAddress/FooterAddress';
import FooterLinks from '../../templates/FooterLinks/FooterLinks';
import { useLocation } from 'react-router-dom';
const Footer = () => {
  const location = useLocation();
  const pathsToHideFooter = ['/get-appointment', '/contact', '/apply-now', '/services/travel-apply-now', '/services/education-apply-now', '/services/salary-advance-apply-now', '/services/credit-card-apply-now'];
  const shouldHideFooter = pathsToHideFooter.includes(location.pathname);
  return (
    <>
      {shouldHideFooter ? "" :
        <section className='footerTop sectionPadding'>

          <FooterTop />

        </section>
      }
      <footer className='footer'>
        <Container>
          <Row className='footerNewsLetter mb-4'>
            <Col md={7} className='pe-5'>
              <NewsLetter />
            </Col>
            <Col md={5} className='ps-5'>
              <div className='whatsApp'>
                <Image src={whatsAppImage} alt="whatsappIcon" width="64" height="64" />
                <div>
                  <Heading tagName="h4" className="h4" title="Apply Using WhatsApp" />
                  <p className='white m-0'>Simple, reliable, private messaging and calling for free*, available all over the India.</p>
                </div>
              </div>
            </Col>
          </Row>
          <hr className='white' />
          <Row className='mt-4'>
            <Col md={4} className='pe-5'>
              <FooterAddress className="footerAddress" />
            </Col>
            <Col md={8}>
              <FooterLinks />
            </Col>

          </Row>
          <hr className='white mb-0' />
          <Row className='py-4 '>
            <Col className='text-center'><p className='white m-0'>Copyright © 2024 - Grownet . All rights reserved. </p></Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
