import React, { useEffect, useMemo, useState, useRef } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Chart from 'chart.js/auto';
import { Link } from 'react-router-dom';
const TravelLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(17);
  const [loanTenure, setLoanTenure] = useState(24);
  const [monthlyEMI, setMonthlyEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loanAmountError, setLoanAmountError] = useState('');
  const [interestRateError, setInterestRateError] = useState('');
  const [loanTenureError, setLoanTenureError] = useState('');
  const chartRef = useRef("");
  const loanAmountHandler = (event) => {
    const value = parseInt(event.target.value);
    setLoanAmount(value);
    if (isNaN(value) || value <= 0) {
      setLoanAmountError('Loan amount must be a positive number');
    } else {
      setLoanAmountError('');
    }
  };
  const interestRateHandler = (event) => {
    const value = parseFloat(event.target.value);
    setInterestRate(value);
    if (isNaN(value) || value < 14 || value > 28) {
      setInterestRateError('Interest rate must be between 14 and 28');
    } else {
      setInterestRateError('');
    }
  };
  const loanTenureHandler = (event) => {
    const value = parseInt(event.target.value);
    setLoanTenure(value);
    if (isNaN(value) || value < 6 || value > 60) {
      setLoanTenureError('Loan tenure must be between 6 and 60 Months');
    } else {
      setLoanTenureError('');
    }
  };

  const [chartData, setChartData] = useState({
    labels: ['Principal', 'Interest'],
    datasets: [
      {
        data: [loanAmount, totalInterest],
        backgroundColor: ['#009B35', '#EF8200'],
        hoverBackgroundColor: ['#009B35', '#EF8200'],
      },
    ],
  });

  const updateChartData = (principal, interest) => {
    const totalPrincipal = parseFloat(principal);
    const totalInterest = parseFloat(interest);
    setChartData({
      labels: ['Principal', 'Interest'],
      datasets: [
        {
          data: [totalPrincipal, totalInterest],
          backgroundColor: ['#009B35', '#EF8200'],
          hoverBackgroundColor: ['#009B35', '#EF8200'],
        },
      ],
    });
  };
  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
    };
  }, []);
  useEffect(() => {
    if (chartRef.current && totalAmount > 0) {
      const newChart = new Chart(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options: chartOptions,
      });

      return () => {
        newChart.destroy();
      };
    }
  }, [chartData, chartOptions, totalAmount]);
  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const formEducation = new FormData();
        formEducation.append('loan_amount', loanAmount);
        formEducation.append('interest_rate', interestRate);
        formEducation.append('loan_tenure', loanTenure);
        const response = await axios.post(
          'https://api.grownetfinance.com/api/calculate-travel-loan',
          formEducation,
          // {
          //   headers: {
          //     'Authorization':process.env.REACT_APP_SECRET_KEY, 
          //   },
          // }
        );
        if (response.data.status === false) {
        } else {
          setMonthlyEMI(response.data.data.monthly_emi);
          setTotalInterest(response.data.data.total_interest_paid);
          setTotalAmount(response.data.data.total_amount_paid);
          //console.log('Principal:', loanAmount);
          //console.log('Interest:', response.data.data.total_interest_paid);
          var intrestPaid = response.data.data.total_interest_paid.replace(/,/g, '')
          updateChartData(loanAmount, intrestPaid);
        }
      } catch (error) {
        console.error('Error fetching data from API', error);
      }
    };
    fetchDataFromAPI();
  }, [loanAmount, interestRate, loanTenure]);
  return (
    <>
      <Row className='calulator--chart g-0 mt-4'>
        <Col className='col-12 col-lg-7 pe-4'>
          <form className='calculatorArea'>
            <div className='input-group'>
              <label>Loan Amount:
                <div className='feild'>
                  <p className='money-symbol'>&#x20B9;</p>
                  <input type="number"
                    min="10000"
                    max="500000"
                    step="10000"
                    value={loanAmount}
                    onChange={loanAmountHandler}
                  />

                </div>
              </label>
              <input
                type="range"
                min="10000"
                max="500000"
                step="10000"
                value={loanAmount}
                onChange={loanAmountHandler}
              />
              {loanAmountError && <p className="error-message">{loanAmountError}</p>}
            </div>
            <div className='input-group'>
              <label>Rate of Interest (P.a):
                <div className='feild'>
                  <input type="number"
                    min="14"
                    max="28"
                    step="0.1"
                    value={interestRate}
                    onChange={interestRateHandler}
                  />
                  <p className='interest-rate-text'>%</p>
                </div>
              </label>
              <input
                type="range"
                min="14"
                max="28"
                step="0.1"
                value={interestRate}
                onChange={interestRateHandler}
              />
              {interestRateError && <p className="error-message">{interestRateError}</p>}
            </div>
            <div className='input-group'>
              <label>Loan Tenure (Month):
                <div className='feild'>
                  <input type="number"
                    min="6"
                    max="60"
                    step="6"
                    value={loanTenure}
                    onChange={loanTenureHandler}
                  />
                  <p className='loan-period-text'>Month</p>
                </div>
              </label>
              <input
                type="range"
                min="6"
                max="60"
                step="6"
                value={loanTenure}
                onChange={loanTenureHandler}
              />
              {loanTenureError && <p className="error-message">{loanTenureError}</p>}
            </div>
            <div className='totalAmount'><p>Total Amount: <span>&#x20B9; {totalAmount}</span></p></div>
          </form>
        </Col>
        <Col className='col-12 col-lg-5 ps-4'>
          <div className='pieChart'>

            <Pie data={chartData} options={chartOptions} style={{ width: '280px', height: '180px' }} />

          </div>
          <div className='infoCalculator'>

            <p>Monthly EMI: <span>&#x20B9; {monthlyEMI}</span></p>
            <p>Total Interest: <span>&#x20B9; {totalInterest}</span></p>
            <div className='mt-4 buttonApply'><Link className="btnfilled button-2 btn-custom" to="https://los.grownetfinance.com/">Apply Now</Link></div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default TravelLoanCalculator
