import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const HeaderButton = () => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <ul className="headerButton">
        <li>
          {/* <Link to="#" className="btnOutline button-1 btn-custom">
            Free CIBIL Score
          </Link> */}
        </li>
        {/* <li>
          <Link to="/apply-now" className="btnfilled button-2 btn-custom">
            Apply Now
          </Link>
        </li> */}

        <div className="dropdown" ref={dropdownRef}>
          <button
            className="btnfilled button-2 btn-custom dropdown-toggle login-dropdown "
            type="button"
            onClick={toggleDropdown}
          >
            Login
          </button>

          {isDropDownOpen && (
            <div className="menu">
              <ul>
                <li>
                  <a href="https://apply.grownetfinance.com/">
                    <i className="ph-bold ph-user"></i>Consumer Login
                  </a>
                </li>
                <li>
                  <a href="https://los.grownetfinance.com/">
                    <i className="ph-bold ph-envelope-simple"></i>Partner Login
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </ul>
    </>
  );
};

export default HeaderButton;