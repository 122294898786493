import React, { useState } from 'react';
import axios from 'axios';
import Input from '../../../atoms/Input/Input'
import Button from '../../../atoms/Button/Button'
import TextArea from '../../../atoms/TextArea/TextArea'
import LoanType from '../Eligibility/LoanType'
// import CountryDropdown from '../CountryDropdown/CountryDropdown';
import StateDropdown from '../StateDropdown/StateDropdown';
import CityDropdown from '../CityDropdown/CityDropdown';
// import ReCAPTCHA from 'react-google-recaptcha';
const ConatctForm = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        loan_type: '',
        message: '',

        state: '',
        city: '',
        pin_code: '',
        employment_status: '',
        term_and_condition: false,
    });

    const [errorMessages, setErrorMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    // const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [loading, setLoading] = useState(false);
    // const [captchaValue, setCaptchaValue] = useState('');
    // const handleCountryChange = () => {
    //     setSelectedCountry(selectedCountry);
    //     console.log('Selected Country:', selectedCountry);
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         country: selectedCountry,
    //     }));
    //     handleChange({
    //         target: {
    //             name: 'country',
    //             value: selectedCountry,
    //         },
    //     });
    // };

    const handleStateChange = (selectedState) => {
        setSelectedState(selectedState);
        console.log('Selected State:', selectedState);
        setFormData((prevData) => ({
            ...prevData,
            state: selectedState,
        }));
        handleChange({
            target: {
                name: 'state',
                value: selectedState,
            },
        });
    };
    const handleCityChange = (selectedCity) => {
        console.log('Selected City:', selectedCity);
        setFormData((prevData) => ({
            ...prevData,
            city: selectedCity,
        }));

        handleChange({
            target: {
                name: 'city',
                value: selectedCity,
            },
        });
    };
    const handleChangeMessage = (event) => {
        formData.message = event.target.value;


    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));

        } else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [name]: emailRegex.test(value) ? '' : 'Invalid email format.',
            }));
        } else {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? '' : `${name.replace('_', ' ')} is required.`,
            }));
        }
        

        setSuccessMessage('');
    };
    const handleEmploymentStatus = (e) => {
        const value = e.target.value;

        setFormData((prevData) => ({
            ...prevData,
            employment_status: value,
        }));

        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            employment_status: '',
        }));



    };
    const submitHandler = async (e) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        // if (!captchaValue) {
        //     alert('Please complete the CAPTCHA.');
        //     return;
        //   }
        const requiredFields = [
            'first_name',
            'email',
            'contact_number',
            'loan_type',
            'state',
            'city',
            'pin_code',
            'employment_status',
        ];
        const newErrorMessages = {};
        requiredFields.forEach((field) => {
            const value = formData[field];
            if (typeof value === 'string' && !value.trim()) {
                newErrorMessages[field] = `${field.replace('_', ' ')} is required.`;
            }
        });
        if (!formData.term_and_condition) {
            newErrorMessages['term_and_condition'] = 'You must agree to the terms and conditions to submit the form.';
        }
        setErrorMessages(newErrorMessages);

        if (Object.keys(newErrorMessages).length > 0) {
            setLoading(false);
            setSuccessMessage('');
            return;
        }

        try {
            const formContact = new FormData();
            formContact.append('first_name', formData.first_name);
            formContact.append('last_name', formData.last_name);
            formContact.append('email', formData.email);
            formContact.append('contact_number', formData.contact_number);
            formContact.append('loan_type', formData.loan_type);
            formContact.append('state', formData.state);
            formContact.append('city', formData.city);
            formContact.append('pin_code', formData.pin_code);
            formContact.append('employment_status', formData.employment_status);
            formContact.append('term_and_condition', formData.term_and_condition);
            formContact.append('message', formData.message);

            const response = await axios.post('https://api.grownetfinance.com/api/contact-us', formContact,
                // {
                //     headers: {
                //         'Authorization': process.env.REACT_APP_SECRET_KEY,
                //     },
                // }

            );
            // const response = sendRequest({
            //     path: '/contact-us',
            //     key: 'contact-us',
            //     body: formContact,
            //   });

            console.log('API Response:', response);


            if (response.data && response.data.status === false) {
                const messagesArray = response.data.message || [];
                const errorMessagesObj = messagesArray.reduce((acc, message, index) => {
                    acc[index.toString()] = message;
                    return acc;
                }, {});
                setErrorMessages(errorMessagesObj);
                setSuccessMessage('');
            } else {
                setErrorMessages({});
                //setSuccessMessage('Form submitted successfully!');

                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number: '',
                    loan_type: '',
                    message: '',
                    state: '',
                    city: '',
                    pin_code: '',
                    employment_status: '',
                    term_and_condition: false,
                });
                setErrorMessages({});
                //setSuccessMessage('Form submitted successfully!');
            }
        } catch (error) {
            console.error('API Error:', error);
            setErrorMessages({ general: 'An error occurred while submitting the form.' });
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className='contactFormDeatils'>
                <div className='input-group first' >
                    <Input
                        type="text"
                        placeholder="First Name*"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                    />
                    {errorMessages.first_name && (
                        <p className='errormessage'>{errorMessages.first_name}</p>
                    )}
                </div>
                <div className='input-group last' >
                    <Input
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                    />

                </div>
                <div className='input-group first' >
                    <Input
                        type="email"
                        placeholder="Email Address*"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errorMessages.email && (
                        <p className='errormessage'>{errorMessages.email}</p>
                    )}
                </div>
                <div className='input-group last' >
                    <Input
                        type="text"
                        placeholder="Contact number*"
                        name="contact_number"
                        value={formData.contact_number}
                        //onChange={handleChange}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/\D/g, '');
                            const truncatedValue = numericValue.slice(0, 14);
                            handleChange({
                                target: {
                                    name: 'contact_number',
                                    value: truncatedValue,
                                },
                            });
                        }}
                    />
                    {errorMessages.contact_number && (
                        <p className='errormessage'>{errorMessages.contact_number}</p>
                    )}
                </div>
                <div className='input-group first' >
                    <LoanType
                        value={formData.loan_type}
                        onChange={(selectedLoanType) => handleChange({ target: { name: 'loan_type', value: selectedLoanType } })}
                    />

                    {errorMessages.loan_type && (
                        <p className='errormessage'>{errorMessages.loan_type}</p>
                    )}
                </div>

                <div className='input-group last'>

                    <select value="101" onChange={handleChange}>
                        <option>India</option>
                    </select>
                </div>

                <div className='input-group first'>
                    <StateDropdown

                        value={formData.state}
                        onSelectState={(selectedState) => {
                            handleStateChange(selectedState);
                            handleChange({ target: { name: 'state', value: selectedState } });
                        }}
                        onChange={(selectedState) => handleStateChange(selectedState)}
                    />
                    {errorMessages.state && (
                        <p className='errormessage'>{errorMessages.state}</p>
                    )}
                </div>

                <div className='input-group last'>

                    <CityDropdown
                        selectedState={selectedState}
                        value={formData.city}
                        onSelectCity={(selectedCity) => {
                            handleCityChange(selectedCity);
                            handleChange({ target: { name: 'city', value: selectedCity } });
                        }}
                        onChange={(onSelectCity) => handleCityChange(onSelectCity)}

                    />
                    {errorMessages.city && (
                        <p className='errormessage'>{errorMessages.city}</p>
                    )}
                </div>


                <div className='input-group first' >
                    <Input
                        type="text"
                        name="pin_code"
                        placeholder="Pin Code*"
                        //onChange={handleChange}
                        value={formData.pin_code}
                        label="Pin Code"
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^\d+]/g, '');
                            const truncatedValue = numericValue.slice(0, 6);
                            handleChange({
                                target: {
                                    name: 'pin_code',
                                    value: truncatedValue,
                                },
                            });
                        }}

                    />
                    {errorMessages.pin_code && (
                        <p className='errormessage'>{errorMessages.pin_code}</p>
                    )}

                </div>
                <div className='input-group radioButton last'>

                    <label className='d-flex position-relative align-items-center radioLabel'>
                        Salaried
                        <Input
                            type="radio"
                            name="employment_status"
                            value="Salaried"
                            checked={formData.employment_status}
                            onChange={handleEmploymentStatus}
                        />
                    </label>
                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                        Self Business
                        <Input
                            type="radio"
                            name="employment_status"
                            value="Self Business"
                            checked={formData.employment_status}
                            onChange={handleEmploymentStatus}
                        />
                    </label>
                    {errorMessages.employment_status && (
                        <p className='errormessage'>{errorMessages.employment_status}</p>
                    )}
                </div>
                <div className='input-group textArea' >
                    <TextArea
                        placeholder="Your message"
                        name="message"
                        value={formData.message}
                        onChange={handleChangeMessage}
                    />

                </div>
                <div className='input-group checkbox'>
                    <label>
                        <input
                            type="checkbox"
                            name="term_and_condition"
                            checked={formData.term_and_condition}
                            onChange={handleChange}
                        />
                        <span>I give consent to the company to evaluate my eligibility for the proposed loan and company or its associates may contact me in this regard.</span>
                    </label>
                    {errorMessages.term_and_condition && (
                        <p className='errormessage'>{errorMessages.term_and_condition}</p>
                    )}
                </div>
                {/* <div className='input-group' >
                    <ReCAPTCHA
                        sitekey="your-recaptcha-site-key"
                        onChange={(value) => setCaptchaValue(value)}
                    />
                    </div> */}
                <div className='input-group submitButton mb-0' >

                    <Button
                        type="submit"
                        label={loading ? 'Submitting...' : 'Submit'}
                        className="submit"
                        onClick={submitHandler}
                        disabled={loading}
                    />
                </div>


                {successMessage && (
                    <div className='succesMessage'>
                        {successMessage}
                    </div>
                )}
            </form>
        </>
    )
}

export default ConatctForm;