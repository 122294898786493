import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import notFound from '../../../assets/img/notFound.png'
import Image from '../../molecules/Images/Image'
import { Link } from 'react-router-dom'
const NotFound  = () => {
  return (
    <>
      <div className='errorpage sectionPadding'>
        <Container >
          <Row >
            <Col className='col-12 text-center'>
              <figure><Image src={notFound} alt="not Found" width="384" height="379" /></figure>
              <h1 class="next-error-h1">Not Found</h1>
              <p>Sorry, the page you are looking for might be in another castle!</p>
              <div className='mt-4'><Link class="btnfilled button-2 btn-custom " to="/">Back To Homepage</Link></div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NotFound 
