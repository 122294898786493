import React from 'react'
import '../Contact/Contact.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import contactImage from '../../../assets/img/contactImage.jpg'
import ContactDetails from '../../molecules/Card/ContactDetails'
import ContactInfo from '../../templates/FooterAddress/ContactInfo'
import ConatctForm from '../../molecules/Form/ConatctForm/ConatctForm'
import MapSection from '../../atoms/MapSection/MapSection'

const Contact = () => {
  return (
    <>

      <section className='position-relative innerBanner'>
        <Image src={contactImage} alt="About Banner" width="1920" height="450" />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Contact Us" />
                <Breadcrumbs />
              </Col>
            </Row>

          </Container>
        </div>
      </section>
      <section className='contactDetails sectionTop'>
        <Container>
          <Row>
            <Col className='col-12 col-lg-8 card border-0 pe-0' >
              <div className=' card-body contactForm'>
                <Heading tagName="h3" className="h3" title="Please Fill This Form To Contact With Us." />
                <ConatctForm />
              </div>
            </Col>
            <Col className='col-12 col-lg-4 card border-0 ps-0' >
              <div className=' card-body contactInfo'>
                <Heading tagName="h3" className="h3 " title="Contact Us" />
                <ContactInfo />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='addressSection sectionPadding'>
        <Container>
          <Row>
            <Col className='text-center'>
              <Heading tagName="h2" className="h2" title="We’d Love To Hear From You" />

            </Col>
          </Row>
          <Row className='mt-4'>
            <ContactDetails />

          </Row>
        </Container>
      </section>

      <MapSection />

    </>
  )
}

export default Contact
