import React, { useState } from 'react'
import Heading from '../../molecules/Headings/Heading'

const Marquee = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`marquee-container ${isHovered ? 'paused' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="marquee-content">
        <Heading tagName="h2" className="h2" title="We are live in Delhi NCR, Gujrat, Karnataka and Maharashtra."/>
      </div>
      
    </div>
  );
};
export default Marquee;

