import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Chart from 'chart.js/auto';
import { Link } from 'react-router-dom';
import SelectField from '../../atoms/Select/SelectField ';
const CompoundInterest = () => {
    const [loanAmount, setLoanAmount] = useState(1000000);
    const [interestRate, setInterestRate] = useState(7.5);
    const [loanTenure, setLoanTenure] = useState(3);
    const [compoundsFrequency, setCompoundsFrequency] = useState(1)
    const [monthlyEMI, setMonthlyEMI] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loanAmountError, setLoanAmountError] = useState('');
    const [interestRateError, setInterestRateError] = useState('');
    const [loanTenureError, setLoanTenureError] = useState('');
    const [compoundFrequencyOptions, setCompoundFrequencyOptions] = useState([
        { value: 1, label: 'Yearly' },
        { value: 2, label: 'Half Yearly' },
        { value: 4, label: 'Quarterly' },
    ]);
    const chartRef = useRef("");
    const loanAmountHandler = (event) => {
        const value = parseInt(event.target.value);
        setLoanAmount(value);
        if (isNaN(value) || value <= 0) {
            setLoanAmountError('Loan amount must be a positive number');
        } else {
            setLoanAmountError('');
        }
    };
    const interestRateHandler = (event) => {
        const value = parseFloat(event.target.value);
        setInterestRate(value);
        if (isNaN(value) || value < 0.1 || value > 30) {
            setInterestRateError('Interest rate must be between 0.1 and 30');
        } else {
            setInterestRateError('');
        }
    };
    const loanTenureHandler = (event) => {
        const value = parseInt(event.target.value);
        setLoanTenure(value);
        if (isNaN(value) || value < 1 || value > 30) {
            setLoanTenureError('Loan tenure must be between 1 and 30 months');
        } else {
            setLoanTenureError('');
        }
    };
    const handleCompoundFrequency = useCallback(
        (event) => {
            setCompoundsFrequency(parseInt(event.target.value));
        },
        [setCompoundsFrequency]
    );
    const [chartData, setChartData] = useState({
        labels: ['Principal', 'Est. Returns'],
        datasets: [
            {
                data: [loanAmount, totalInterest],
                backgroundColor: ['#009B35', '#EF8200'],
                hoverBackgroundColor: ['#009B35', '#EF8200'],
            },
        ],
    });

    const updateChartData = (principal, interest) => {
        const totalFdPrincipal = parseFloat(principal);
        const totalFdInterest = parseFloat(interest);
        setChartData({
            labels: ['Principal', 'Est. Returns'],
            datasets: [
                {
                    data: [totalFdPrincipal, totalFdInterest],
                    backgroundColor: ['#009B35', '#EF8200'],
                    hoverBackgroundColor: ['#009B35', '#EF8200'],
                },
            ],
        });
    };
    const chartOptions = useMemo(() => {
        return {
            responsive: true,
            maintainAspectRatio: false,
        };
    }, []);
    useEffect(() => {
        if (chartRef.current && totalAmount > 0) {
            const newChart = new Chart(chartRef.current, {
                type: 'doughnut',
                data: chartData,
                options: chartOptions,
            });

            return () => {
                newChart.destroy();
            };
        }
    }, [chartData, chartOptions, totalAmount]);

    useEffect(() => {
        const fetchDataFromAPI = async () => {
            try {
                const formCompoundInterest = new FormData();
                formCompoundInterest.append('loan_amount', loanAmount);
                formCompoundInterest.append('interest_rate', interestRate);
                formCompoundInterest.append('loan_tenure', loanTenure);
                formCompoundInterest.append('compounds_frequency', compoundsFrequency);

                const response = await axios.post(
                    'https://api.grownetfinance.com/api/calculate-compound-intrest',
                    formCompoundInterest,
                    // {
                    //     headers: {
                    //         'Authorization': process.env.REACT_APP_SECRET_KEY,
                    //     },
                    // }
                );

                if (response.data.status === false) {

                } else if (
                    response.data.data &&
                    Array.isArray(response.data.data.number_of_times_compounded_annually)
                ) {
                    setCompoundFrequencyOptions(
                        response.data.data.number_of_times_compounded_annually
                    );
                } else {
                    setMonthlyEMI(response.data.data.principal_amount);
                    setTotalInterest(response.data.data.total_interest);
                    setTotalAmount(response.data.data.final_amount);

                    var interestPaid = response.data.data.total_interest.replace(/,/g, '');
                    updateChartData(loanAmount, interestPaid);
                }
            } catch (error) {
                console.error('Error fetching data from API', error);
            }
        };

        fetchDataFromAPI();
    }, [loanAmount, interestRate, loanTenure, compoundsFrequency]);

    return (
        <>
            <Row className='calulator--chart g-0 mt-4'>
                <Col className='col-12 col-lg-7 pe-4'>
                    <form className='calculatorArea'>
                        <div className='input-group'>
                            <label>Total Investment
                                <div className='feild'>
                                    <p className='money-symbol'>&#x20B9;</p>
                                    <input type="number"
                                        min="100000"
                                        max="10000000"
                                        step="10000"
                                        value={loanAmount}
                                        onChange={loanAmountHandler}
                                    />

                                </div>
                            </label>
                            <input
                                type="range"
                                min="100000"
                                max="10000000"
                                step="10000"
                                value={loanAmount}
                                onInput={loanAmountHandler}
                            />
                            {loanAmountError && <p className="error-message">{loanAmountError}</p>}
                        </div>
                        <div className='input-group'>
                            <label>Rate of Interest (P.a):
                                <div className='feild'>
                                    <input type="number"
                                        min="0.1"
                                        max="50"
                                        step="0.1"
                                        value={interestRate}
                                        onChange={interestRateHandler}
                                    />
                                    <p className='interest-rate-text'>%</p>
                                </div>
                            </label>
                            <input
                                type="range"
                                min="0.1"
                                max="50"
                                step="0.1"
                                value={interestRate}
                                onChange={interestRateHandler}
                            />
                            {interestRateError && <p className="error-message">{interestRateError}</p>}
                        </div>
                        <div className='input-group'>
                            <label>Time Period (Years):
                                <div className='feild'>
                                    <input type="number"
                                        min="1"
                                        max="30"
                                        step="1"
                                        value={loanTenure}
                                        onChange={loanTenureHandler}
                                    />
                                    <p className='loan-period-text'>year</p>
                                </div>
                            </label>
                            <input
                                type="range"
                                min="1"
                                max="30"
                                step="1"
                                value={loanTenure}
                                onChange={loanTenureHandler}
                            />
                            {loanTenureError && <p className="error-message">{loanTenureError}</p>}
                        </div>

                        <div className='input-group'>
                            <SelectField
                                readOnly
                                label="Compound Frequency"
                                id="CompoundFrequency"
                                options={compoundFrequencyOptions}
                                value={compoundsFrequency}
                                onChange={handleCompoundFrequency}
                            />
                        </div>
                    </form>
                </Col>
                <Col className='col-12 col-lg-5 ps-4'>
                    <div className='pieChart'>

                        <Pie data={chartData} options={chartOptions} style={{ width: '280px', height: '180px' }} />

                    </div>
                    <div className='infoCalculator'>


                        <p>Total Interest <span>&#x20B9; {totalInterest}</span></p>
                        <p>Principal Amount <span>&#x20B9; {monthlyEMI}</span></p>
                        <p>Total Amount: <span>&#x20B9; {totalAmount}</span></p>
                        <div className='mt-4 buttonApply'><Link className="btnfilled button-2 btn-custom" to="https://los.grownetfinance.com/login">Apply Now</Link></div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CompoundInterest
