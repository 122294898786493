import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';


import './App.css';
import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import Home from './components/pages/HomePage/Home';
import About from './components/pages/About/About';
import Services from './components/pages/Services/Services'
import FinancialTools from './components/pages/FinancialTools/FinancialTools';
import HowItWorks from './components/pages/HowItWorks/HowItWorks'
import Contact from './components/pages/Contact/Contact'
import GetAppointment from './components/pages/GetAppointment/GetAppointment';
import ApplyNow from './components/pages/ApplyNow/ApplyNow';
import TravelLoan from './components/pages/Services/TravelLoan/TravelLoan';
import EducationLoan from './components/pages/Services/EducationLoan/EducationLoan';
import SalaryAdvance from './components/pages/Services/SalaryAdvance/SalaryAdvance';
import CreditCardBalanceTransfer from './components/pages/Services/CreditCardBalanceTransfer/CreditCardBalanceTransfer';
import CheckYourEligibility from './components/pages/CheckYourEligibility/CheckYourEligibility';
import HomeLoanEMICalculator from './components/pages/FinancialTools/HomeLoanEMICalculator/HomeLoanEMICalculator';
import BusinessLoanEMICalculator from './components/pages/FinancialTools/BusinessLoanEMICalculator/BusinessLoanEMICalculator';
import CompoundInterestCalculator from './components/pages/FinancialTools/CompoundInterestCalculator/CompoundInterestCalculator';
import LoanPrepaymentCalculator from './components/pages/FinancialTools/LoanPrepaymentCalculator/LoanPrepaymentCalculator';
import RdCalculator from './components/pages/FinancialTools/RdCalculator/RdCalculator';
import FDCalculator from './components/pages/FinancialTools/FdCalculator/FDCalculator';
import PageList from './utils/Seo/PageList';
import Career from './components/pages/Career/Career';
import MainLoader from './components/atoms/MainLoader/MainLoader';
import EducationApplyNow from './components/pages/Services/EducationLoan/EducationApplyNow';
import TravelApplyNow from './components/pages/Services/TravelLoan/TravelApplyNow';
import SalaryAdvanceApplyNow from './components/pages/Services/SalaryAdvance/SalaryAdvanceApplyNow';
import CreditCardApplyNow from './components/pages/Services/CreditCardBalanceTransfer/CreditCardApplyNow';
import TermsAndCondition from './components/pages/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import HelpCenter from './components/pages/HelpCenter/HelpCenter';
import NotFound from './components/pages/Errorpage/NotFound ';
const routes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  { path: '/services', element: <Services /> },
  { path: '/services/travel-loan', element: <TravelLoan /> },
  { path: '/services/education-loan', element: <EducationLoan /> },
  { path: '/services/salary-advance', element: <SalaryAdvance /> },
  { path: '/services/credit-card-balance-transfer', element: <CreditCardBalanceTransfer /> },
  { path: '/financial-tools', element: <FinancialTools /> },
  { path: '/financial-tools/home-loan-emi-calculator', element: <HomeLoanEMICalculator /> },
  { path: '/financial-tools/business-loan-emi-calculator', element: <BusinessLoanEMICalculator /> },
  { path: '/financial-tools/compound-interest-calculator', element: <CompoundInterestCalculator /> },
  { path: '/financial-tools/loan-prepayment-calculator', element: <LoanPrepaymentCalculator /> },
  { path: '/financial-tools/rd-calculator', element: <RdCalculator /> },
  { path: '/financial-tools/fd-calculator', element: <FDCalculator /> },
  { path: '/how-it-works', element: <HowItWorks /> },
  { path: '/contact', element: <Contact /> },
  { path: '/get-appointment', element: <GetAppointment /> },
  // { path: '/apply-now', element: <ApplyNow /> },
  { path: '/check-your-eligibility', element: <CheckYourEligibility /> },
  { path: '/career', element: <Career /> },
  { path: '/services/travel-apply-now', element: <TravelApplyNow /> },
  { path: '/services/education-apply-now', element: <EducationApplyNow /> },
  { path: '/services/salary-advance-apply-now', element: <SalaryAdvanceApplyNow /> },
  { path: '/services/credit-card-apply-now', element: <CreditCardApplyNow /> },
  { path: '/terms-and-condition', element: <TermsAndCondition /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/help-center', element: <HelpCenter /> },
  { path: '/not-found', element: <NotFound /> }

];


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {

        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (


    <>
      {loading && <MainLoader />}


      <Header />

      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <>
                <PageList currentRoute={route.path} />
                {route.element}
              </>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />

    </>


  );
}

export default App;
