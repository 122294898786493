import React, { useState } from 'react'
import axios from 'axios'
import Col from 'react-bootstrap/esm/Col'
import Heading from '../../Headings/Heading'
// import LoanType from '../Eligibility/LoanType'
import Input from '../../../atoms/Input/Input'
import Button from '../../../atoms/Button/Button'
// import GenerateEmailOTP from '../GenerateEmailOTP/GenerateEmailOTP'
import ValidateOTP from '../ValidateOTP/ValidateOTP'
import StateDropdown from '../StateDropdown/StateDropdown'
import CityDropdown from '../CityDropdown/CityDropdown'
import Modal from 'react-modal';
import iconCheck from '../../../../assets/img/icon-check.svg'
import closeCircleImage from '../../../../assets/img/close-circle-otp.svg'
import Image from '../../Images/Image'
import TermsModal from '../../TermsPopup/TermsPopup'
import ValidatePhoneOtp from '../ValidateOTP/ValidatePhoneOtp'
// import ReCAPTCHA from 'react-google-recaptcha';
const EducationApplyNowForm = () => {
    Modal.setAppElement('#root');
    const [educationLoanApplication, setEducationLoanApplication] = useState({
        loan_type: 'Education Loan',
        loan_amount: '',
        guardian_monthly_income: '',
        //expected_loan: '',
        study_location: '',
        full_name: '',
        email: '',
        phone: '',
        marital_status: '',
        date_of_birth: '',
        employee_status: '',
        zip_code: '',
        pan_no: '',
        aadhar_no: '',
        itr_no: '',
        state: '',
        city: '',
        itr_doc: '',
        pan_doc: '',
        pan_back:'',
        aadhar_front: '',
        aadhar_back: '',
        driving_licence_back: '',
        driving_licence_front: '',
        bank_statement: '',
        residence_proof: '',
        lease_agreement: '',
        passport: '',
        term_and_condition:false,
        privacy_policy: false,
        aadhar_consent:false,
        pan_consent:false
    });

    const [errorMessages, setErrorMessages] = useState('');
    const [emailError, setEmailError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [emailSuccessMessage, setEmailSuccessMessage] = useState('');
    const [validatedEmail, setValidatedEmail] = useState('');
    // const [captchaValue, setCaptchaValue] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false);
    /*--Phone*/
    const [phoneError, setPhoneError] = useState('');
    const [otpGeneratedPhone, setOtpGeneratedPhone] = useState(false);
    const [phoneSuccessMessage, setPhoneSuccessMessage] = useState('');
    const [validatedPhone, setValidatedPhone] = useState('');
    const [buttonClickedPhone, setButtonClickedPhone] = useState(false);
    /*--PanCard*/
    const [panCardError, setPanCardError] = useState('');
    const [panCardSuccessMessage, setPanCardSuccessMessage] = useState('');
    const [validatedPanCard, setValidatedPanCard] = useState('');
    /*--AadharCard*/
    const [aadharError, setAadharError] = useState('');
    const [aadharSuccessMessage, setAadharSuccessMessage] = useState('');
    const [validatedAadharCard, setValidatedAadharCard] = useState('');

    const handleFileChange = (name, file) => {

        setEducationLoanApplication((prevData) => ({
            ...prevData,
            [name]: file,
        }));
    };


    const handleStateChange = (selectedState) => {
        setSelectedState(selectedState);

        setEducationLoanApplication((prevData) => ({
            ...prevData,
            state: selectedState,
        }));
        educationApplyNowHandlar({
            target: {
                name: 'state',
                value: selectedState,
            },
        });
    };

    const handleCityChange = (selectedCity) => {
        setEducationLoanApplication((prevData) => ({
            ...prevData,
            city: selectedCity,

        }));

        educationApplyNowHandlar({
            target: {
                name: 'city',
                value: selectedCity,
            },
        });
    };
    const handleChangePanConsent = async  (e) => {
        const { name, checked } = e.target;
        if (!educationLoanApplication.pan_no.trim()) {
          return;
        }
        setEducationLoanApplication((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          pan_consent: '', 
        }));
    
      
      if (checked) {
        try {
         
          const panData = {
            pan: educationLoanApplication.pan_no
          }
          const response = await axios.post('https://losapi.grownetfinance.com/api/v1/customer/verifyPAN', panData);
    
          //console.log('Verify PAN Response:', response.data);
    
          if (response.status === 200 && response.data.result && response.data.result.data.code === '1000') {
              //const { name, email, phone, date_of_birth, address_data } = response.data.result.data.pan_data;
              setPanCardError('');
              setPanCardSuccessMessage(response.data.result.data.message)
              setValidatedPanCard(true);
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                //pan_consent: response.data.result.data.message,
                // name: name,
                // email: email,
                // phone: phone,    
                // date_of_birth:date_of_birth,        
                // pincode: address_data.pincode,
            }));
          } else {
              setPanCardError(response.data.result.data.message);
              setPanCardSuccessMessage('');
              setValidatedPanCard(false);
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                //pan_consent: response.data.result.data.message,
              }));
          }
        } catch (error) {
            console.error('Verify PAN API Error:', error.response?.data || error.message);
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              pan_consent: error.response?.data?.message,
            }));
        }
      }
      };
      
      const handleChangeAadharConsent = async (e) => {
        const { name, checked } = e.target;
        if (!educationLoanApplication.aadhar_no.trim()) {
          return;
        }
    
        setEducationLoanApplication((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          aadhar_consent: '', 
        }));
    
      
      if (checked) {
        try {
         
          const AadhaarData = {
            aadhaar: educationLoanApplication.aadhar_no
          }
          const response = await axios.post('https://losapi.grownetfinance.com/api/v1/customer/verifyAadhaar', AadhaarData);
    
           
    
          if (response.status === 200 && response.data.result.data &&  response.data.result.data) {
              setAadharError('');
              setAadharSuccessMessage(response.data.result.data.message)
              setValidatedAadharCard(true);
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                //aadhar_consent: response.data.result.data.message,
            }));
          } else {          
              setAadharError(response.data.result.message);
              setAadharSuccessMessage('');
              setValidatedAadharCard(false);
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                //aadhar_consent: response.data.result.data.message,
              }));
          }
        } catch (error) {
            console.error('Verify Aadhar API Error:', error.response?.data || error.message);
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              pan_consent: error.response?.data?.message,
            }));
          }
        }
      };
    const handleChangeTrems = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setEducationLoanApplication((prevData) => ({
            ...prevData,
            [name]: checked,
          }));
    
        } else {
    
            setEducationLoanApplication((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
    
    
    
        setSuccessMessage('');
    };
    const handleChangePrivacy = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setEducationLoanApplication((prevData) => ({
            ...prevData,
            [name]: checked,
            }));

        } else {

            setEducationLoanApplication((prevData) => ({
            ...prevData,
            [name]: value,
            }));
        }


        setSuccessMessage('');
    };
    const validatePanCard = (pan) => {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return panRegex.test(pan);
    };
    
    const handlePanChange = (e) => {
        const { name, value } = e.target;
        educationLoanApplication((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          pan_no: '', 
        }));
        setPanCardError(''); 
        setValidatedPanCard(false);
        const alphaNumericValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        const truncatedValue = alphaNumericValue.slice(0, 10);
        educationApplyNowHandlar({
          target: {
            name: 'pan_no',
            value: truncatedValue,
          },
        });
    
        if (!validatePanCard(truncatedValue) && truncatedValue.length === 10) {
          setErrorMessages((prev) => ({ ...prev, pan_no: 'Invalid PAN number' }));
        } else {
          setErrorMessages((prev) => ({ ...prev, pan_no: '' }));
        }
      };
      const handleAadharChange = (e) => {
        const { name, value } = e.target;
        educationLoanApplication((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          aadhar_no: '',
        }));
        setAadharError(''); 
        setValidatedAadharCard(false); 
      };

    const MAX_FILE_SIZE_MB = 2;
    const educationApplyNowHandlar = (e) => {
        const { name, value } = e.target;
        if ((name === 'loan_amount' || name === 'guardian_monthly_income' || name === 'zip_code') && isNaN(value)) {
            return;
        }

        if (e.target.type === 'file') {
            const file = e.target.files[0];

            if (file && file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                return;
            }

            handleFileChange(name, file);
        } else {
            setEducationLoanApplication((prevData) => ({
                ...prevData,
                [name]: value,
                employment_status: value,
            }));
        }


        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [name]: (typeof value === 'string' ? value.trim() : value) ? '' : `${name.replace(/_/g, ' ')} is required.`,
            employment_status: '',
        }));
        setSuccessMessage('');
    };
    const handleValidationDone = (isEmailValid) => {
        setValidatedEmail(isEmailValid);
    };
    const handlePhoneValidationDone = (isPhoneValid) => {
        setValidatedPhone(isPhoneValid);
      };
    const educationApplySubmitHandler = async (e) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        // if (!captchaValue) {
        //   alert('Please complete the CAPTCHA.');
        //   return;
        // }
        const requiredFields = [
            'loan_type',
            'loan_amount',
            'guardian_monthly_income',
            //'expected_loan',
            'study_location',
            'full_name',
            'email',
            'phone',
            'marital_status',
            'date_of_birth',
            'employee_status',
            'zip_code',
            'pan_no',
            'aadhar_no',
            'itr_no',
            'state',
            'city',
            'itr_doc',
            'pan_doc',
            'pan_back',
            'aadhar_front',
            'aadhar_back',
            'bank_statement',
            'term_and_condition',
            'privacy_policy',
            'aadhar_consent',
            'pan_consent'
        ];
        const newErrorMessages = {};
        requiredFields.forEach((field) => {
            const value = educationLoanApplication[field];
            if (typeof value === 'string' && !value.trim()) {
                newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
            }
            else if (!educationLoanApplication.term_and_condition) {
                newErrorMessages['term_and_condition'] = 'You must agree to the terms and conditions to submit the form.';
            }
            else if (!educationLoanApplication.privacy_policy) {
            newErrorMessages['privacy_policy'] = 'You must agree to the Privacy Policy to submit the form.';
            }
            else if (!educationLoanApplication.aadhar_consent) {
                newErrorMessages['aadhar_consent'] = 'You must agree to the terms and conditions to submit the form.';
            }
            else if (!educationLoanApplication.pan_consent) {
            newErrorMessages['pan_consent'] = 'You must agree to the Privacy Policy to submit the form.';
            }

            
        });
        setErrorMessages(newErrorMessages);
        if (Object.keys(newErrorMessages).length > 0) {
            setLoading(false);
            return;
        }

        try {
            const educationApplyNowData = new FormData();
            educationApplyNowData.append('loan_type', educationLoanApplication.loan_type);
            educationApplyNowData.append('loan_amount', educationLoanApplication.loan_amount);
            educationApplyNowData.append('guardian_monthly_income', educationLoanApplication.guardian_monthly_income);
            //educationApplyNowData.append('expected_loan', educationLoanApplication.expected_loan);
            educationApplyNowData.append('study_location', educationLoanApplication.study_location);
            educationApplyNowData.append('full_name', educationLoanApplication.full_name);
            educationApplyNowData.append('email', educationLoanApplication.email);
            educationApplyNowData.append('phone', educationLoanApplication.phone);
            educationApplyNowData.append('marital_status', educationLoanApplication.marital_status);
            educationApplyNowData.append('date_of_birth', educationLoanApplication.date_of_birth);
            educationApplyNowData.append('employee_status', educationLoanApplication.employee_status);
            educationApplyNowData.append('zip_code', educationLoanApplication.zip_code);
            educationApplyNowData.append('pan_no', educationLoanApplication.pan_no);
            educationApplyNowData.append('aadhar_no', educationLoanApplication.aadhar_no);
            educationApplyNowData.append('itr_no', educationLoanApplication.itr_no);
            educationApplyNowData.append('state', educationLoanApplication.state);
            educationApplyNowData.append('city', educationLoanApplication.city);
            educationApplyNowData.append('itr_doc', educationLoanApplication.itr_doc);
            educationApplyNowData.append('pan_doc', educationLoanApplication.pan_doc);
            educationApplyNowData.append('pan_back', educationLoanApplication.pan_back);
            educationApplyNowData.append('aadhar_front', educationLoanApplication.aadhar_front);
            educationApplyNowData.append('aadhar_back', educationLoanApplication.aadhar_back);
            educationApplyNowData.append('driving_licence_front', educationLoanApplication.driving_licence_front);
            educationApplyNowData.append('driving_licence_back', educationLoanApplication.driving_licence_back);
            educationApplyNowData.append('bank_statement', educationLoanApplication.bank_statement);
            educationApplyNowData.append('residence_proof', educationLoanApplication.residence_proof);
            educationApplyNowData.append('lease_agreement', educationLoanApplication.lease_agreement);
            educationApplyNowData.append('passport', educationLoanApplication.passport);
            educationApplyNowData.append('term_and_condition', educationLoanApplication.term_and_condition);
            educationApplyNowData.append('privacy_policy', educationLoanApplication.privacy_policy);
            educationApplyNowData.append('aadhar_consent', educationLoanApplication.aadhar_consent);
            educationApplyNowData.append('pan_consent', educationLoanApplication.pan_consent);
            

            console.log('FormData API PARAMS:', educationLoanApplication);

            const response = await axios.post('https://api.grownetfinance.com/api/education-loan-application', educationApplyNowData,
                // {
                //     headers: {
                //         'Authorization':process.env.REACT_APP_SECRET_KEY, 
                //     },
                // }
            );

            console.log('API Response:', response);

            if (response.data === 200) {
                if (response.data.status === true) {
                    setSuccessMessage(response.data.message);
                    setErrorMessages({});
                    setModalIsOpen(true);
                    console.log('Modal State:', modalIsOpen);
                } else {
                    throw new Error(response.data.message);
                }



            } else {
                setSuccessMessage(response.data.message);
                setModalIsOpen(true);
                console.log('Modal State:', modalIsOpen);
                setEducationLoanApplication({
                    loan_type: 'Education Loan',
                    loan_amount: '',
                    guardian_monthly_income: '',
                    //expected_loan: '',
                    study_location: '',
                    full_name: '',
                    email: '',
                    phone: '',
                    marital_status: '',
                    date_of_birth: '',
                    employee_status: '',
                    zip_code: '',
                    pan_no: '',
                    aadhar_no: '',
                    itr_no: '',
                    state: '',
                    city: '',
                    itr_doc: '',
                    pan_doc: '',
                    pan_back:'',
                    aadhar_front: '',
                    aadhar_back: '',
                    driving_licence_back: '',
                    driving_licence_front: '',
                    bank_statement: '',
                    residence_proof: '',
                    lease_agreement: '',
                    passport: '',
                    term_and_condition: false,
                    privacy_policy: false,
                    aadhar_consent:'',
                    pan_consent:''
                });
                setErrorMessages(response.data.message);
            }
        } catch (error) {
            console.error('API Error:', error);

        } finally {
            setLoading(false);
        }

    };
    const phoneVerifyHandlar =async (e)=>{
        e.preventDefault();
        if (buttonClickedPhone) {
          return;
        }
    
        setButtonClickedPhone(true);
        const requiredFields = ['phone', educationLoanApplication.phone];
        const newErrorMessages = {};
    
        requiredFields.forEach((field) => {
          const value = educationLoanApplication.phone.trim();
          if (!value) {
            newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
          }
        });
    
        try {
          
          const phoneData = {
            phone: educationLoanApplication.phone,
          };
          const response = await axios.post('https://losapi.grownetfinance.com/api/v1/auth/user/otp', phoneData,
           
          );
          console.log('Response:', response.data);
          if (response.status === 200 && response.data.result === true) {
            setPhoneSuccessMessage(response.data.message);
            setValidatedPhone(true);
            setOtpGeneratedPhone(true);
            setPhoneError(''); // Clear any previous error message
          } else {
            setPhoneError(response.data.message || 'Unexpected error occurred');
            setPhoneSuccessMessage(''); // Clear success message on error
          }
        } catch (error) {
          console.error('API Error:', error.response?.data || error.message);
          setPhoneError(
            error.response?.data?.message || 'Network error, please try again later.'
          );
          setPhoneSuccessMessage(''); // Clear success message on error
        } finally {
          setButtonClickedPhone(false);
        }
      }
    const educationGenerateOTPHandler = async (e) => {
        e.preventDefault();
        if (buttonClicked) {
            return;
        }

        setButtonClicked(true);
        const requiredFields = ['email', educationLoanApplication.email];
        const newErrorMessages = {};

        requiredFields.forEach((field) => {
            const value = educationLoanApplication.email.trim();
            if (!value) {
                newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
            }
        });

        try {
            const generateOTPFormData = new FormData();
            generateOTPFormData.append('email', educationLoanApplication.email);

            const response = await axios.post('https://api.grownetfinance.com/api/generate-email-otp', generateOTPFormData,
                // {
                //     headers: {
                //     'Authorization':process.env.REACT_APP_SECRET_KEY, 
                //     },
                // }
            );

            if (response.status === 200 && response.data.status === true) {
                if (response.data.status === true) {
                    setEmailSuccessMessage(response.data.message);
                    setValidatedEmail(true);
                    setOtpGenerated(true);
                    setEmailError('');
                } else if (response.data.status === false) {

                    setEmailError(response.data.message);
                    setEmailSuccessMessage('');
                } else {
                    throw new Error(response.data.message);
                }
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setOtpGenerated(false);
            setEmailError(error.message);
            setEmailSuccessMessage('');
            console.error('Error:', error.message);
        } finally {
            setButtonClicked(false);
        }
    };

    const handleCloseModal = (e) => {
        e.preventDefault();
        setModalIsOpen(false);
        setEducationLoanApplication({
            loan_type: "Education Loan",
            loan_amount: '',
            guardian_monthly_income: '',
            //expected_loan: '',
            study_location: '',
            full_name: '',
            email: '',
            phone: '',
            marital_status: '',
            date_of_birth: '',
            employee_status: '',
            zip_code: '',
            pan_no: '',
            aadhar_no: '',
            itr_no: '',
            state: '',
            city: '',
            itr_doc: '',
            pan_doc: '',
            pan_back:'',
            aadhar_front: '',
            aadhar_back: '',
            driving_licence_back: '',
            driving_licence_front: '',
            bank_statement: '',
            residence_proof: '',
            lease_agreement: '',
            passport: '',
            term_and_condition: true,
            privacy_policy: true,
            aadhar_consent:'',
            pan_consent:''
        });
        window.location.reload();
    };
    return (
        <>
            <Col className='col-12'>
                <form className='applyNow' >
                    <div className='applyDetails'>
                        <div className='Heading'>
                            <Heading tagName="h2" className="h2" title="Loan Details" />
                        </div>
                        <div className='loanDetails'>

                            <div className='input-group first' >

                                <Input
                                    type="text"
                                    name="loan_type"
                                    value={educationLoanApplication.loan_type}
                                    onChange={educationApplyNowHandlar}
                                    defaultValue="Education Loan"
                                    label={<span>Loan Type <span style={{ color: 'red' }}>*</span></span>}
                                    className="feild_disabled"
                                />

                                {errorMessages.loan_type && (
                                    <p className='errormessage'>{errorMessages.loan_type}</p>
                                )}
                            </div>
                            <div className='input-group last' >

                                <Input
                                    type="text"
                                    name="loan_amount"

                                    placeholder="Loan Amount*"
                                    value={educationLoanApplication.loan_amount}
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Loan Amount <span style={{ color: 'red' }}>*</span></span>}
                                />
                                {errorMessages.loan_amount && (
                                    <p className='errormessage'>{errorMessages.loan_amount}</p>
                                )}
                            </div>
                            <div className='input-group first' >
                                <Input
                                    type="text"
                                    name="guardian_monthly_income"
                                    placeholder="Guardian Monthly Income"
                                    value={educationLoanApplication.guardian_monthly_income}
                                    onChange={educationApplyNowHandlar}

                                    label={<span>Guardian Monthly Income <span style={{ color: 'red' }}>*</span></span>}
                                />
                                {errorMessages.guardian_monthly_income && (
                                    <p className='errormessage'>{errorMessages.guardian_monthly_income}</p>
                                )}
                            </div>

                            {/* <div className='input-group last' >
                                <Input
                                    type="text"
                                    name="expected_loan"
                                    placeholder="Expected Loan*"
                                    value={educationLoanApplication.expected_loan}
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Expected Loan <span style={{ color: 'red' }}>*</span></span>}
                                />


                                {errorMessages.expected_loan && (
                                    <p className='errormessage'>{errorMessages.expected_loan}</p>
                                )}
                            </div> */}
                            <div className='input-group radioButton last' >
                                <label className='mainLabel'>Study Location<span style={{ color: 'red' }}>*</span></label>
                                <div className='d-flex w-100 align-items-center'>
                                    <label className='d-flex position-relative align-items-center radioLabel'>
                                        Domestic
                                        <Input
                                            type="radio"
                                            name="study_location"
                                            value="Domestic"
                                            checked={educationLoanApplication.study_location}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                                        International
                                        <Input
                                            type="radio"
                                            name="study_location"
                                            value="International"
                                            checked={educationLoanApplication.study_location}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                </div>


                                {errorMessages.study_location && (
                                    <p className='errormessage'>{errorMessages.study_location}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='applyDetails'>
                        <div className='Heading'>
                            <Heading tagName="h2" className="h2" title="Personal Details" />
                        </div>
                        <div className='loanDetails'>
                            <div className='input-group ' >
                                <Input
                                    type="text"
                                    name="full_name"
                                    placeholder="Full Name*"
                                    value={educationLoanApplication.full_name}
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Full Name <span style={{ color: 'red' }}>*</span></span>}
                                />
                                {errorMessages.full_name && (
                                    <p className='errormessage'>{errorMessages.full_name}</p>
                                )}
                            </div>

                            <div className='input-group buttonValidate first' >
                                <div className='position-relative withicon w-100'>
                                <Input
                                    type="text"
                                    name="phone"
                                    placeholder="Contact number*"
                                    onChange={educationApplyNowHandlar}
                                    value={educationLoanApplication.phone}
                                    label={<span>Phone No <span style={{ color: 'red' }}>*</span></span>}
                                    
                                />
                                {validatedPhone && !phoneError && (
                                    <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                                )}
                                {phoneError && !validatedPhone && (
                                    <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                                )}
                                </div>
                                {educationLoanApplication.phone && (
                                    
                                <Button
                                    type="submit"
                                    onClick={phoneVerifyHandlar}
                                    label={otpGeneratedPhone ? "Resend" : "Generate OTP"}
                                    className={`submit ${buttonClicked ? 'fade' : ''}`}
                                    disabled={otpGeneratedPhone || validatedPhone === 'status' || buttonClicked}
                                />
                                )}
                                {phoneError && (
                                <p className="errormessageApply">{phoneError}</p>
                                )}
                                {phoneSuccessMessage && (
                                <p className={`message successMessageApply`}>{phoneSuccessMessage}</p>
                                )}
                                {errorMessages.phone && (
                                <p className="errormessageApply">{errorMessages.phone}</p>
                                )}
                            </div>
                            
                            {validatedPhone && otpGeneratedPhone && (
                                <div className='input-group buttonValidate last'>
                                <ValidatePhoneOtp
                                    phone={educationLoanApplication.phone}
                                    onValidationDone={handlePhoneValidationDone}
                                />

                                </div>
                            )}
                            <div className='input-group buttonValidate first'>
                                <div className='position-relative withicon w-100'>
                                    <Input
                                        type="text"
                                        name="email"
                                        placeholder="Email Address*"
                                        onChange={educationApplyNowHandlar}
                                        value={educationLoanApplication.email}
                                        label={<span>Email <span style={{ color: 'red' }}>*</span></span>}

                                    />
                                    {validatedEmail && !emailError && (
                                        <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                                    )}
                                    {emailError && !validatedEmail && (
                                        <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                                    )}
                                </div>
                                {educationLoanApplication.email && (
                                    <Button
                                        type="submit"
                                        onClick={educationGenerateOTPHandler}
                                        label={otpGenerated ? "Resend" : "Generate OTP"}
                                        className={`submit ${buttonClicked ? 'fade' : ''}`}
                                        disabled={otpGenerated || validatedEmail === 'status' || buttonClicked}
                                    />
                                )}

                                {emailError && (
                                    <p className="errormessageApply">{emailError}</p>
                                )}
                                {emailSuccessMessage && (
                                    <p className={`message successMessageApply`}>{emailSuccessMessage}</p>
                                )}
                                {errorMessages.email && (
                                    <p className="errormessageApply">{errorMessages.email}</p>
                                )}
                            </div>
                            {validatedEmail && otpGenerated && (
                                <div className='input-group buttonValidate last'>
                                    <ValidateOTP
                                        email={educationLoanApplication.email}
                                        onValidationDone={handleValidationDone}
                                    />

                                </div>
                            )}


                            <div className='input-group radioButton first'>
                                <label className='mainLabel'>Marital Status<span style={{ color: 'red' }}>*</span></label>
                                <div className='d-flex w-100 align-items-center'>
                                    <label className='d-flex position-relative align-items-center radioLabel'>
                                        Single
                                        <Input
                                            type="radio"
                                            name="marital_status"
                                            value="Single"
                                            checked={educationLoanApplication.marital_status}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                                        Married
                                        <Input
                                            type="radio"
                                            name="marital_status"
                                            value="Married"
                                            checked={educationLoanApplication.marital_status}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                </div>
                                {errorMessages.marital_status && (
                                    <p className='errormessage'>{errorMessages.marital_status}</p>
                                )}


                            </div>
                            <div className='input-group radioButton last'>
                                <label className='mainLabel'>Profession <span style={{ color: 'red' }}>*</span></label>
                                <div className='d-flex  w-100 align-items-center'>
                                    <label className='d-flex position-relative align-items-center radioLabel'>
                                        Salaried
                                        <Input
                                            type="radio"
                                            name="employee_status"
                                            value="Salaried"
                                            checked={educationLoanApplication.employee_status}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                                        Self Business
                                        <Input
                                            type="radio"
                                            name="employee_status"
                                            value="Self Business"
                                            checked={educationLoanApplication.employee_status}
                                            onChange={educationApplyNowHandlar}
                                        />
                                    </label>
                                </div>
                                {errorMessages.employee_status && (
                                    <p className='errormessage'>{errorMessages.employee_status}</p>
                                )}

                            </div>
                            <div className='input-group first' >
                                <Input
                                    type="date"
                                    name="date_of_birth"
                                    value={educationLoanApplication.date_of_birth}
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Date Of Birth <span style={{ color: 'red' }}>*</span></span>}

                                />
                                {errorMessages.date_of_birth && (
                                    <p className='errormessage'>{errorMessages.date_of_birth}</p>
                                )}
                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="text"
                                    name="zip_code"
                                    placeholder="Pin Code"
                                    // onChange={educationApplyNowHandlar}
                                    value={educationLoanApplication.zip_code}
                                    label={<span>Pin Code <span style={{ color: 'red' }}>*</span></span>}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        const truncatedValue = numericValue.slice(0, 6);
                                        educationApplyNowHandlar({
                                            target: {
                                                name: 'zip_code',
                                                value: truncatedValue,
                                            },
                                        });
                                    }}

                                />
                                {errorMessages.zip_code && (
                                    <p className='errormessage'>{errorMessages.zip_code}</p>
                                )}
                            </div>
                            <div className='input-group first' >
                                <label>Country</label>
                                <select value="101" onChange={educationApplyNowHandlar}>
                                    <option>India</option>
                                </select>
                            </div>

                            <div className='input-group last'>
                                <StateDropdown
                                    value={educationLoanApplication.state}
                                    onSelectState={(selectedState) => {
                                        handleStateChange(selectedState);
                                        educationApplyNowHandlar({ target: { name: 'state', value: selectedState } });
                                    }}
                                    onChange={(selectedState) => handleStateChange(selectedState)}
                                />
                                {errorMessages.state && (
                                    <p className='errormessage'>{errorMessages.state}</p>
                                )}
                            </div>


                            {selectedState && (
                                <div className='input-group first'>
                                    <CityDropdown
                                        selectedState={selectedState}
                                        value={educationLoanApplication.city}
                                        onSelectCity={(selectedCity) => {
                                            handleCityChange(selectedCity);
                                            educationApplyNowHandlar({ target: { name: 'city', value: selectedCity } });
                                        }}
                                        onChange={(onSelectCity) => handleCityChange(onSelectCity)}

                                    />
                                    {errorMessages.city && (
                                        <p className='errormessage'>{errorMessages.city}</p>
                                    )}
                                </div>
                            )}


                        </div>
                    </div>
                    <div className='applyDetails'>
                        <div className='Heading'>
                            <Heading tagName="h2" className="h2" title="KYC Details" />
                        </div>
                        <div className='loanDetails'>

                        <div className='input-group ' >
                            <div className='position-relative withicon w-100'>
                            <Input
                                type="text"
                                name="pan_no"
                                placeholder="Pan Number*"
                                value={educationLoanApplication.pan_no}
                                onChange={handlePanChange}
                                label={<span>Pan Number <span style={{ color: 'red' }}>*</span></span>}
                                minLength={10}
                                maxLength={10}
                                pattern="[A-Za-z0-9]*"
                                disabled={validatedPanCard}
                            />
                            {validatedPanCard && !panCardError && (
                                <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                                )}
                                {panCardError && !validatedPanCard && (
                                <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                                )}
                            </div>
                            {errorMessages.pan_no && (
                            <p className='errormessage'>{errorMessages.pan_no}</p>
                            )}
                        </div>
                        <div className='input-group checkbox'>
                            <label>
                            <input
                                type="checkbox"
                                name="pan_consent"
                                checked={educationLoanApplication.pan_consent}
                                onChange={handleChangePanConsent}
                                disabled={!educationLoanApplication.pan_no.trim()} 
                            />
                            <span>Consent </span>
                            </label>
                            {panCardError && (
                            <p className="errormessageApply">{panCardError}</p>
                            )}
                            {panCardSuccessMessage && (
                            <p className={`message successMessageApply`}>{panCardSuccessMessage}</p>
                            )}
                            {errorMessages.pan_consent && (
                            <p className='errormessage'>{errorMessages.pan_consent}</p>
                            )}
                            
                        </div>
                            <div className='input-group first'>
                                <Input
                                    type="file"
                                    name="pan_doc"
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Pan Card Front<span style={{ color: 'red' }}>*</span></span>}
                                />
                                {educationLoanApplication.pan_doc && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.pan_doc)} alt="Pan Card" />
                                    </div>
                                )}
                                {errorMessages.pan_doc && (
                                    <p className='errormessage'>{errorMessages.pan_doc}</p>
                                )}

                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="file"
                                    name="pan_back"
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Pan Card Back</span>}
                                />
                                {educationLoanApplication.pan_back && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.pan_back)} alt="Pan Back" />
                                    </div>
                                )}
                                {errorMessages.pan_back && (
                                    <p className='errormessage'>{errorMessages.pan_back}</p>
                                )}

                            </div>
                            <div className='input-group'>
                                <div className='position-relative withicon w-100'>
                                
                                <Input
                                    type="text"
                                    name="aadhar_no"
                                    placeholder="Aadhar Number*"
                                    value={educationLoanApplication.aadhar_no}
                                    onChange={handleAadharChange}
                                    label={<span>Aadhar Number<span style={{ color: 'red' }}>*</span></span>}
                                    minLength={12}
                                    maxLength={12}
                                    pattern="\d{12}"
                                    disabled={validatedAadharCard}
                                />
                                {validatedAadharCard && !aadharError && (
                                    <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                                )}
                                {aadharError && !validatedAadharCard && (
                                    <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                                )}
                                </div>
                                
                                {errorMessages.aadhar_no && (
                                <p className='errormessage'>{errorMessages.aadhar_no}</p>
                                )}
                            </div>
                            <div className='input-group checkbox'>
                                <label>
                                <input
                                    type="checkbox"
                                    name="aadhar_consent"
                                    checked={educationLoanApplication.aadhar_consent}
                                    onChange={handleChangeAadharConsent}
                                    disabled={!educationLoanApplication.aadhar_no.trim()}
                                />
                                <span>Consent </span>
                                </label>
                                {aadharError && (
                                <p className="errormessageApply">{aadharError}</p>
                                )}
                                {aadharSuccessMessage && (
                                <p className='message successMessageApply'>{aadharSuccessMessage}</p>
                                )}
                                {errorMessages.aadhar_consent && (
                                <p className='errormessage'>{errorMessages.aadhar_consent}</p>
                                )}
                                
                            </div>
                            <div className='input-group first' >
                                <Input
                                    type="file"
                                    name="aadhar_front"
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Aadhar Front<span style={{ color: 'red' }}>*</span></span>}
                                />
                                {educationLoanApplication.aadhar_front && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.aadhar_front)} alt="Aadhar Front" />
                                    </div>
                                )}
                                {errorMessages.aadhar_front && (
                                    <p className='errormessage'>{errorMessages.aadhar_front}</p>
                                )}

                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="file"
                                    name="aadhar_back"
                                    onChange={educationApplyNowHandlar}
                                    label={<span>Aadhar Back<span style={{ color: 'red' }}>*</span></span>}
                                />
                                {educationLoanApplication.aadhar_back && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.aadhar_back)} alt="Aadhar Back" />
                                    </div>
                                )}
                                {errorMessages.aadhar_back && (
                                    <p className='errormessage'>{errorMessages.aadhar_back}</p>
                                )}

                            </div>
                        </div>
                            <div className='Heading'>
                                <Heading tagName="h2" className="h2" title="Other Details" />
                            </div>
                        <div className='loanDetails'>
                            <div className='input-group first' >
                                <Input
                                    type="text"
                                    name="itr_no"
                                    placeholder="ITR Number*"
                                    value={educationLoanApplication.itr_no}

                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        const truncatedValue = numericValue.slice(0, 10);
                                        educationApplyNowHandlar({
                                            target: {
                                                name: 'itr_no',
                                                value: truncatedValue,
                                            },
                                        });
                                    }}
                                    label={<span>ITR Number<span style={{ color: 'red' }}>*</span></span>}
                                    minLength={10}
                                    maxLength={10}
                                    pattern="[0-9]*"
                                />
                                {errorMessages.itr_no && (
                                    <p className='errormessage'>{errorMessages.itr_no}</p>
                                )}
                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="file"
                                    name="itr_doc"
                                    onChange={educationApplyNowHandlar}

                                    label={<span>Form 16 / Last 3 Year ITR <span style={{ color: 'red' }}>*</span></span>}
                                />
                                {educationLoanApplication.itr_doc && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.itr_doc)} alt="Itr Details" />
                                    </div>
                                )}
                                {errorMessages.itr_doc && (
                                    <p className='errormessage'>{errorMessages.itr_doc}</p>
                                )}

                            </div>
                            <div className='input-group first' >

                                <Input
                                    type="file"
                                    name="driving_licence_front"
                                    onChange={educationApplyNowHandlar}
                                    label="Driving Licence Front"
                                />
                                {educationLoanApplication.driving_licence_front && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.driving_licence_front)} alt="driving licence front" />
                                    </div>
                                )}

                            </div>
                            <div className='input-group last' >

                                <Input
                                    type="file"
                                    name="driving_licence_back"
                                    onChange={educationApplyNowHandlar}
                                    label="Driving Licence  Back"
                                />
                                {educationLoanApplication.driving_licence_back && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.driving_licence_back)} alt="driving licence Back" />
                                    </div>
                                )}

                            </div>
                            <div className='input-group first' >
                                <Input
                                    type="file"
                                    name="bank_statement"
                                    onChange={educationApplyNowHandlar}
                                    label={<span>6 Months Bank Statement / Salary Slip <span style={{ color: 'red' }}>*</span></span>}
                                />
                                {educationLoanApplication.bank_statement && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.bank_statement)} alt="Statement" />
                                    </div>
                                )}
                                {errorMessages.bank_statement && (
                                    <p className='errormessage'>{errorMessages.bank_statement}</p>
                                )}

                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="file"
                                    name="residence_proof"
                                    onChange={educationApplyNowHandlar}
                                    label="Valid water/electricity/ LPG bill"
                                />
                                {educationLoanApplication.residence_proof && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.residence_proof)} alt="residence" />
                                    </div>
                                )}


                            </div>
                            <div className='input-group first' >
                                <Input
                                    type="file"
                                    name="lease_agreement"
                                    onChange={educationApplyNowHandlar}
                                    label="Current House lease agreement"
                                />
                                {educationLoanApplication.lease_agreement && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.lease_agreement)} alt="agreement" />
                                    </div>
                                )}


                            </div>
                            <div className='input-group last' >
                                <Input
                                    type="file"
                                    name="passport"
                                    onChange={educationApplyNowHandlar}
                                    label="Passport"
                                />
                                {educationLoanApplication.passport && (
                                    <div className='imagePrev w-100'>
                                        <img src={URL.createObjectURL(educationLoanApplication.passport)} alt="passport" />
                                    </div>
                                )}


                            </div>
                        </div>
                        <div className='input-group checkbox'>
              <label>
                <input
                  type="checkbox"
                  name="term_and_condition"
                  checked={educationLoanApplication.term_and_condition}
                  onChange={handleChangeTrems}
                />
                <span>I agree to be bound by  the company policy on Privacy, <TermsModal /> and Disclaimer.</span>
              </label>
              {errorMessages.term_and_condition && (
                <p className='errormessage'>{errorMessages.term_and_condition}</p>
              )}
            </div>
            <div className='input-group checkbox'>
              <label>
                <input
                  type="checkbox"
                  name="privacy_policy"
                  checked={educationLoanApplication.privacy_policy}
                  onChange={handleChangePrivacy}
                />
                <span>I give consent to the company to evaluate my eligibility for the proposed loan and company or its associates may contact me in this regard. </span>
              </label>
              {errorMessages.privacy_policy && (
                <p className='errormessage'>{errorMessages.privacy_policy}</p>
              )}
            </div>
                        {/* <div className='input-group ' >
              <ReCAPTCHA
                sitekey="your-recaptcha-site-key"
                onChange={(value) => setCaptchaValue(value)}
              />
            </div> */}

                        <div className='applyDetails'>
                            <div className='input-group submitButton mb-0' >

                                <Button
                                    type="submit"
                                    label={loading ? 'Submitting...' : 'Submit'}
                                    className={`submit ${loading ? 'fade' : ''}`}
                                    onClick={educationApplySubmitHandler}
                                    disabled={loading}
                                />
                            </div>
                            

                            {/* {successMessage && (
                  <div className='succesMessage'>
                    {successMessage}
                  </div>
                )} */}

                        </div>

                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Success Modal"
                        className="modalSuccess">
                        <div className='modalBody'>
                            <p>{successMessage}</p>
                            <button type='button' className='buttonClose' onClick={(e) => handleCloseModal(e)}>Close</button>
                        </div>

                    </Modal>
                </form>
            </Col>

        </>
    )
}

export default EducationApplyNowForm
