import React from 'react';

const MapSection = () => {
  return (
    <section className='mapSection'>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4694.693880501077!2d73.92380867519152!3d18.513442282578698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c16c31233d0d%3A0x32b520199108351d!2sPentagon%201!5e1!3m2!1sen!2sin!4v1738219056445!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

      
export default MapSection;
