import React from 'react'
import Heading from '../../molecules/Headings/Heading'
import { Link } from 'react-router-dom'

const Information = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className='footerLinks'>
      <Heading className="h4" tagName="h4" title="Information" />
      <ul className='links'>
        <li><Link to="/" onClick={scrollToTop} >Home </Link></li>
        <li><Link to="/about" onClick={scrollToTop}  >About </Link></li>
        <li><Link to="/how-it-works" onClick={scrollToTop}  >How it Works </Link></li>
        
        {/* <li><Link to="/news" onClick={scrollToTop}  >News</Link></li> */}
        <li><Link to="/contact" onClick={scrollToTop}  >Contact </Link></li>
        <li><Link to="/career" onClick={scrollToTop}  >Career</Link></li>
        {/* <li><Link to="/apply-now" onClick={scrollToTop}  >Apply Now</Link></li> */}
        <li><Link to="/get-appointment" onClick={scrollToTop}  >Get Appointment</Link></li>
      </ul>
    </div>
  )
}

export default Information
